var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-row',[_c('customer-list-bar',{attrs:{"clients":_vm.is_active_customer,"isDisabled":_vm.is_disabled_customer}})],1),(_vm.renderComponent)?_c('div',{staticClass:"mt-4",attrs:{"id":"newCustomerList"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":[
              { name: 'Enabled', enabled: true },
              { name: 'Disabled', enabled: false },
              { name: 'All', enabled: null } ],"item-value":"enabled","item-text":"name","label":"Status"},on:{"input":function($event){return _vm.updateCustomerStatus(_vm.customerStatus)}},model:{value:(_vm.customerStatus),callback:function ($$v) {_vm.customerStatus=$$v},expression:"customerStatus"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":_vm.users,"item-value":"id","item-text":"full_name","label":"Analyst"},on:{"input":function($event){return _vm.updateCustomersByUser(_vm.customerByUser)}},model:{value:(_vm.customerByUser),callback:function ($$v) {_vm.customerByUser=$$v},expression:"customerByUser"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"placeholder":"Filter Results"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.customers,"items-per-page":-1,"disable-sort":"","search":_vm.search,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('td',[(item.enabled)?_c('v-icon',{staticClass:"mr-2 rowIconEnabled",attrs:{"color":"#58ABE7","medium":""}},[_vm._v("mdi-check-circle ")]):_vm._e(),(!item.enabled)?_c('v-icon',{staticClass:"mr-2",staticStyle:{"color":"#112748 !important","caret-color":"#112748 !important"},attrs:{"color":"#112748","medium":""}},[_vm._v("mdi-checkbox-blank-circle ")]):_vm._e()],1)]}},{key:"item.manage",fn:function(ref){
            var item = ref.item;
return [_c('v-row',[_c('v-col',{staticClass:"mr-2",attrs:{"cols":"1"}},[(item.status !== 'Disabled')?_c('v-icon',{staticClass:"rowIcon",on:{"click":function($event){return _vm.customerReports(item)}}},[_vm._v(" mdi-table-large ")]):_vm._e(),(item.status == 'Disabled')?_c('v-icon',{staticClass:"rowIcon",attrs:{"disabled":""}},[_vm._v(" mdi-table-large-remove ")]):_vm._e()],1),_c('v-col',{staticClass:"mr-2",attrs:{"cols":"1"}},[_c('v-icon',{staticClass:"rowIcon",on:{"click":function($event){return _vm.customerItems(item)}}},[_vm._v(" mdi-format-list-bulleted-type ")])],1),_c('v-col',{staticClass:"ml-0",attrs:{"cols":"1"}},[_c('v-icon',{staticClass:"rowIcon",on:{"click":function($event){return _vm.customerProfile(item)}}},[_vm._v(" mdi-account-circle ")])],1)],1)]}}],null,false,3338351836)}),_c('v-snackbar',{attrs:{"color":"#58abe7","centered":"","timeout":3000,"elevation":"24"},model:{value:(_vm.showClipboard),callback:function ($$v) {_vm.showClipboard=$$v},expression:"showClipboard"}},[_vm._v(" "+_vm._s(_vm.showMsg)+" ")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }