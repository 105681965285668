import {} from "@/clients/tracelight";

import DatePicker from "@/components/DatePicker.vue";
import { formatDate } from "@/utils/common";
import moment from "moment";
import { setTimeout } from "core-js";
import store from "@/store/index";

export default {
  name: "clientIdentity",
  components: { DatePicker },
  data: () => ({
    isFormValid: false,
    generalRule: [(value) => !!value || "Name is required."],
    formatDate: formatDate,
    snackbar: false,
    snackbarContent: null,
    clientId: null,
    editProfile: false,
    editProducts: false,
    editDomains: false,
    editEmails: false,
    moment: moment,
    statusSelection: null,
    product: {},
    domain: null,
    user: {},
    creationMode: false,
    endDate: null,
    renderComponent: true,
    loaded: false,
  }),
  created() {
    this.loaded = false;
    this.clientId = this.$route.params.clientId;
    if (this.clientId == "create_client") {
      store.dispatch("clientIdentity/getCustomers");
      this.creationMode = true;
      this.statusSelection = "Enabled";
      this.loaded = true;
    } else {
      this.creationMode = false;
      store
        .dispatch("clientIdentity/getProfile", this.clientId)
        .then((a) => (this.loaded = true));
      store.dispatch("clientIdentity/getUsers");
      store.dispatch("clientIdentity/getCustomers");
      store.dispatch("clientIdentity/getCustomerUsers", this.clientId);
      store.dispatch("clientIdentity/getProductsBegin", this.clientId);
      store.dispatch("clientIdentity/getEmailDomains", this.clientId);
    }
  },

  methods: {
    digitalFootprint() {
      window.location.href =
        window.location.origin +
        "/customer/" +
        this.$route.params.clientId +
        "/items";
    },
    customerReports() {
      window.location.href =
        window.location.origin +
        "/customer/" +
        this.$route.params.clientId +
        "/reports";
    },
    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    partnerSelect(partner_id) {
      store.dispatch("clientIdentity/getProductsContinue", partner_id);
    },
    productSelect(product_name) {
      const product = this.productList.filter(function (product) {
        return product.name == product_name;
      });
      console.log(product_name, product);

      store.dispatch("clientIdentity/getProductsEnd", product[0].id);
    },
    formHandlerProfileCreate: function (e) {
      e.preventDefault();

      store
        .dispatch("clientIdentity/createNewCustomer", {
          profile: this.profile,
        })
        .then((data) => {
          console.log(data);

          this.snackbar = true;

          if (data.data.created == true) {
            this.snackbarContent = data.message;
            setTimeout(() => {
              let routeData = this.$router.resolve({
                path: "/customer/" + data.id,
              });
              window.location.href = routeData.href;
            }, 1000);
          }
          if (data.data.created == false) {
            this.snackbarContent = data.message;
            return;
          }
        });
    },
    formHandlerProfile: function (e) {
      e.preventDefault();
      this.editProfile = false;
      console.warn(this.profile);

      store
        .dispatch("clientIdentity/updateClient", {
          client_id: this.clientId,
          profile: this.profile,
        })
        .then((data) => {
          this.snackbar = true;
          if (data.message) {
            this.snackbarContent = data.message;
          }
          store.dispatch("clientIdentity/getProfile", this.clientId);
          store.dispatch("clientIdentity/getCustomerUsers", this.clientId);
        });
    },
    formHandlerProducts: function (e) {
      e.preventDefault();
      const termObj = this.terms.filter((t) => {
        return t.id == this.product.term;
      });
      if (termObj.length == 1) {
        const termLengthString = termObj[0].name.replace(/[^0-9]/g, "");
        const termLength = termLengthString.replace(/['"]+/g, "");
        const days = Number(termLength) * 365;
        const sd = new Date(this.product.start_date);
        const ed = new Date(this.product.end_date);
        const differenceInTime = ed.getTime() - sd.getTime();
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);
        const checkDifference = Math.abs(differenceInDays - days);
        console.log(checkDifference);
        if (checkDifference >= 10) {
          this.snackbar = true;
          this.snackbarContent = `Your selected term length and start/end date values are ${checkDifference} days apart. They can be no longer than 10 days apart.`;
          return;
        }
      }
      if (this.product.status == "Enabled") {
        this.product.enabled = true;
      } else {
        this.product.enabled = false;
      }

      store
        .dispatch("clientIdentity/createPurchase", {
          client_id: this.clientId,
          product: this.product,
        })
        .then((data) => {
          this.snackbar = true;
          console.log(data);
          if (data.message) {
            this.snackbarContent = data.message;
          }
          store.dispatch("clientIdentity/getProductsBegin", this.clientId);
          this.editProducts = false;
        });
    },
    termToEndDate(term) {
      const termObj = this.terms.filter(function (t) {
        return t.name == term;
      });
      if (termObj.length > 0) {
        const [termLength, unit] = termObj[0].name.split(" ");
        const value = Number(termLength);
        this.endDate = moment()
          .add(value, unit.toLowerCase())
          .format("YYYY-MM-DD");
        this.updateDateRangeEnd([this.endDate, null]);
        this.forceRerender();
      }
    },
    updateDateRangeStart: function (dates) {
      this.product.start_date = dates[0];
    },
    updateDateRangeEnd: function (dates) {
      this.endDate = dates[0];
      this.product.end_date = dates[0];
    },
    deletePurchase: function (purchase_id) {
      store
        .dispatch("clientIdentity/deletePurchase", {
          client_id: this.clientId,
          purchase_id: purchase_id,
        })
        .then((data) => {
          this.snackbar = true;
          if (data.message) {
            this.snackbarContent = data.message;
          }
          store.dispatch("clientIdentity/getProductsBegin", this.clientId);
        });
    },
    deleteDomain: function (item) {
      store
        .dispatch("clientIdentity/deleteEmailDomain", {
          client_id: this.clientId,
          domain_id: item.id,
        })
        .then((data) => {
          this.snackbar = true;
          if (data.message) {
            this.snackbarContent = data.message;
          }
          store.dispatch("clientIdentity/getEmailDomains", this.clientId);
        });
    },
    deleteEmail: function (item) {
      store
        .dispatch("clientIdentity/deleteCustomerUserEmail", {
          client_id: this.clientId,
          customer_user_id: item.id,
        })
        .then((data) => {
          this.snackbar = true;
          if (data.message) {
            this.snackbarContent = data.message;
          }
          store.dispatch("clientIdentity/getCustomerUsers", this.clientId);
        });
    },
    addDomain: function () {
      store
        .dispatch("clientIdentity/createEmailDomain", {
          client_id: this.clientId,
          domain: this.domain,
        })
        .then((data) => {
          this.snackbar = true;
          if (data.message) {
            this.snackbarContent = data.message;
          }
          this.domain = null;
          store.dispatch("clientIdentity/getEmailDomains", this.clientId);
        });
    },
    addEmail: function () {
      if (!this.user.email || !this.user.first_name || !this.user.last_name) {
        this.snackbarContent =
          "Error, the user email, first and last name are all required!";
        this.snackbar = true;
        return;
      }

      const filter =
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

      if (!filter.test(this.user.email)) {
        this.snackbarContent = "Please provide a valid email address!";
        this.snackbar = true;
        return;
      }

      store
        .dispatch("clientIdentity/createCustomerUser", {
          client_id: this.clientId,
          user: this.user,
        })
        .then((data) => {
          console.log(data);
          this.snackbar = true;
          if (data.message) {
            this.snackbarContent = data.message;
          }
          this.user = {};
          store.dispatch("clientIdentity/getCustomerUsers", this.clientId);
        })
        .catch((error) => {
          this.snackbar = true;
          if (error.message) {
            this.snackbarContent = error.message;
          }
        });
    },
  },
  computed: {
    approvedUsers() {
      const domains = this.domains.map((d) => {
        return d.domain;
      });

      const users = [];
      for (const user of this.users) {
        const emailDomain = user.email.split("@")[1];
        if (domains.includes(emailDomain)) {
          users.push(user);
        }
      }
      return users;
    },
    users() {
      const users = store.state.clientIdentity.users || [];
      for (const user of users) {
        user.full_name = user.first_name + " " + user.last_name;

        if (user.full_name.length == 1) {
          user.full_name = user.email;
        }
      }
      return users;
    },
    customerUsers() {
      const customerUsers = store.state.clientIdentity.customerUsers || {};

      for (const customerUser of customerUsers) {
        if (customerUser.user_type_enabled == true) {
          customerUser.status = "Enabled";
        } else {
          customerUser.status = "Disabled";
        }
      }
      return customerUsers;
    },
    profile() {
      if (this.creationMode == false) {
        const profile = store.state.clientIdentity.profile;
        const users = store.state.clientIdentity.users;
        const customers = store.state.clientIdentity.customers;

        if (profile.primary_analyst) {
          profile.primary_analyst_full_name =
            profile.primary_analyst.first_name +
            " " +
            profile.primary_analyst.last_name;

          const checkUser = users.filter((user) => {
            return user.email == profile.primary_analyst.email;
          });

          profile.primary_analyst_id = checkUser[0];
          profile.previous_primary_analyst = profile.primary_analyst.id;
        }

        if (profile.secondary_analyst) {
          profile.secondary_analyst_full_name =
            profile.secondary_analyst.first_name +
            " " +
            profile.secondary_analyst.last_name;
          const checkUser = users.filter((user) => {
            return user.email == profile.secondary_analyst.email;
          });

          profile.secondary_analyst_id = checkUser[0];
          profile.previous_secondary_analyst = profile.secondary_analyst.id;
        }

        let noAnalystMsg = "Please assign an analyst";

        if (profile.primary_analyst_full_name == null) {
          profile.primary_analyst_full_name = noAnalystMsg;
        }

        if (profile.secondary_analyst_full_name == null) {
          profile.secondary_analyst_full_name = noAnalystMsg;
        }
        if (profile.enabled == true) {
          this.statusSelection = "Enabled";
        }
        if (profile.enabled == false) {
          this.statusSelection = "Disabled";
        }

        if (
          profile.parent_customer == "null" ||
          profile.parent_customer == null
        ) {
          profile.parent_customer_name = "No parent company";
        } else {
          const parent_customer = customers.filter(function (customer) {
            return customer.id == profile.id;
          });

          if (parent_customer.length == 1) {
            profile.parent_customer_name = parent_customer[0].name;
          }
        }

        return profile;
      } else {
        const profile = {};
        return profile;
      }
    },
    products() {
      const products = store.state.clientIdentity.products || [];
      return products;
    },
    productList() {
      const productList = store.state.clientIdentity.productList || [];
      return productList;
    },
    partners() {
      const partners = store.state.clientIdentity.partners || [];
      partners.unshift({ name: "None", client_id: "" });
      return partners;
    },
    terms() {
      const terms = store.state.clientIdentity.terms || [];
      return terms;
    },
    emails() {
      const emails = store.state.clientIdentity.emails || [];
      return emails;
    },
    domains() {
      const domains = store.state.clientIdentity.domains || [];
      return domains;
    },
    customers() {
      const customers = store.state.clientIdentity.customers || [];
      customers.sort((a, b) => (a.name > b.name) * 2 - 1);
      customers.unshift({ name: "None", client_id: null });
      return customers;
    },
  },
  watch: {
    editProducts: function (val) {
      if (val !== null) {
        this.product = {};
        store.commit("clientIdentity/replaceValue", {
          key: "terms",
          value: [],
        });
        store.commit("clientIdentity/replaceValue", {
          key: "productList",
          value: [],
        });
      }
    },
    statusSelection: function (val) {
      if (val !== null) {
        if (val == "Disabled") {
          this.profile.enabled = false;
        }
        if (val == "Enabled") {
          this.profile.enabled = true;
        }
      }
    },
  },
};
