import {
  getCustomer,
  listCustomers,
  listUsers,
} from "../../clients/tracelight";

import chai from "chai";
import store from "@/store/index";
export function testCustomerList() {
  const testCases = [];
  testCases.push((checkTest) => {
    store.dispatch("customerList/getCustomers").then((rCustomerList) => {
      checkTest(
        () => {
          chai.expect(rCustomerList).to.be.a("array");
          chai.expect(rCustomerList[0]).to.be.a("object");
        },
        "Customer list should be an array of objects",
        rCustomerList
      );
    });

    const paramState = {
      All: {
        all: true,
        customer_enabled: null,
      },
      Enabled: {
        all: null,
        customer_enabled: true,
      },
      Disabled: {
        all: null,
        customer_enabled: false,
      },
    };

    /* for (const key of Object.keys(paramState)) { */

    function checkFilters(keys) {
      const key = keys.pop();
      const updateParams = paramState[key];

      for (const newParam of Object.keys(updateParams)) {
        store.commit("customerList/replaceValue", {
          key: newParam,
          value: updateParams[newParam],
        });
      }

      store.dispatch("customerList/getCustomers").then((rCustomerList) => {
        checkTest(
          () => {
            for (const customer of rCustomerList) {
              for (const paramKey of Object.keys(updateParams)) {
                // The existence of these "if"" statements is proof that we need to do better API design

                let responseParamKey = paramKey;

                if (updateParams[paramKey] == undefined) {
                  continue;
                }

                if (paramKey == "all") {
                  continue;
                }

                if (paramKey == "customer_enabled") {
                  responseParamKey = "enabled";
                }

                chai
                  .expect(customer[responseParamKey])
                  .to.equal(updateParams[paramKey]);
              }
            }
          },
          `Customer list "${key}" filter should return ${JSON.stringify(
            updateParams
          )}`,
          rCustomerList
        );
        if (keys.length > 0) {
          checkFilters(keys);
        }
      });
    }

    checkFilters(Object.keys(paramState));

    const pay = {};
    const par = {};
    listUsers(pay, par)
      .then(function (result) {
        store.commit("customerList/fillUsers", result.data);
      })
      .catch(function (error) {
        console.debug(error);
      });

    let users = [...store.state.customerList.users];

    function getCustomersByUser(users) {
      const user = users.pop();
      const payload = {};

      if (user) {
        const params = {
          results_per_page: 3000,
          user_id: user.external_id,
        };
        listCustomers(payload, params)
          .then((rCustomerList) => {
            let customers = [...rCustomerList.data];

            function verifyCustomerUser(customers) {
              const customer = customers.pop();
              if (customer) {
                const pay = {};
                const par = {
                  customer_id: customer.external_id,
                };
                getCustomer(pay, par).then(function (res) {
                  let analyst = false;

                  if (
                    res.data.primary_analyst_id == user.external_id ||
                    res.data.secondary_analyst_id == user.external_id
                  ) {
                    analyst = true;
                    console.log(
                      res.data.primary_analyst_id,
                      res.data.secondary_analyst_id,
                      user.external_id
                    );
                  }
                  checkTest(
                    () => {
                      chai.expect(analyst).to.be.true;
                    },
                    `Verifying if ${user.email} is actually set as primary or secondary analyst`,
                    analyst
                  );
                });
                if (customers.length > 0) {
                  verifyCustomerUser(customers);
                }
              }
            }
            verifyCustomerUser(customers);

            checkTest(
              () => {
                chai.expect(rCustomerList.data).to.have.lengthOf.above(0);
              },
              `Original call should have customers that have ${user.email} set as primary or secondary analyst should appear`,
              rCustomerList.data
            );
            if (users.length > 0) {
              getCustomersByUser(users);
            }
          })
          .catch((er) => {
            console.log(er);
            return false;
          });
      }
    }

    getCustomersByUser(users);
  });
  return testCases;
}
