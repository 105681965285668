<template>
  <v-dialog
    :overlay-color="'#000000'"
    :overlay-opacity="0.9"
    v-model="isOpen"
    max-width="600"
  >
    <v-card>
      <v-card-title class="pl-12">
        <h4 class="accent--text font-weight-light ml-n2">{{ title }}</h4>
      </v-card-title>
      <div class="px-10" style="min-height: 250px">
        <v-row>
          <v-col v-for="(action, i) in actions" cols="12">
            <app-button
              :clickHandler="() => performAction(action.fn)"
              :disabled="action.disabled"
            >
              {{ action.label }}
            </app-button>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import AppButton from "@/components/AppButton.vue";

export default {
  name: "dialog-action",
  components: { AppButton },
  data: () => ({
    isOpen: false,
  }),
  props: {
    requestOpen: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "A Reusable Action Dialog",
    },
    actions: {
      type: Array,
      default: () => {
        return [
          {
            label: "Delete",
            fn: () => {
              alert("Delete action");
            },
          },
          {
            label: "Disable",
            fn: () => {
              alert("Disable action");
            },
          },
        ];
      },
    },
  },
  methods: {
    performAction(fn) {
      fn();
      this.isOpen = false;
    },
  },
  watch: {
    requestOpen(val) {
      if (val == true) {
        this.isOpen = true;
      }
    },
  },
};
</script>
