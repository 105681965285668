import {
  createCustomer,
  createUser,
  customersCustomerIdEmailDomainsDomainIdDelete,
  customersCustomerIdEmailDomainsGet,
  customersCustomerIdEmailDomainsPost,
  customersCustomerIdPurchasesGet,
  customersCustomerIdPurchasesPost,
  customersCustomerIdUsersGet,
  customersCustomerIdUsersPost,
  deleteCustomer,
  deleteCustomerPurchase,
  deleteCustomerUser,
  getCustomer,
  getProductByPartner,
  getProductTerms,
  listCustomers,
  listUsers,
  partnersGet,
  updateCustomer,
} from "../../clients/tracelight";

const clientIdentity = {
  namespaced: true,
  state: {
    users: [],
    customerUsers: [],
    products: [],
    productList: [],
    domains: [],
    profile: {},
    customers: [],
    partners: [],
    terms: [],
  },
  actions: {
    getCustomers(context) {
      return new Promise((resolve) => {
        const payload = {};
        const params = {
          results_per_page: 3000,
        };

        listCustomers(payload, params)
          .then(function (result) {
            context.commit("replaceValue", {
              key: "customers",
              value: result.data.data,
            });
            resolve(result.data);
          })
          .catch(function (error) {
            console.debug(error);
          });
      });
    },
    deleteEmailDomain(context, contents) {
      return new Promise((resolve) => {
        const payload = {};
        const params = {
          client_id: contents.client_id,
          domain_id: contents.domain_id,
        };
        customersCustomerIdEmailDomainsDomainIdDelete(payload, params).then(
          function (result) {
            resolve(result.data);
          }
        );
      });
    },
    deleteCustomerUserEmail(context, contents) {
      return new Promise((resolve) => {
        const payload = {};
        const params = {
          client_id: contents.id,
          user_id: contents.customer_user_id,
        };
        deleteCustomerUser(payload, params).then(function (result) {
          resolve(result.data);
        });
      });
    },
    deletePurchase(context, contents) {
      return new Promise((resolve) => {
        const payload = {};
        const params = {
          client_id: contents.client_id,
          purchase_id: contents.purchase_id,
        };
        deleteCustomerPurchase(payload, params).then(function (result) {
          resolve(result.data);
        });
      });
    },
    createNewCustomer(context, contents) {
      if (contents.profile.parent_customer == "None") {
        contents.profile.parent_customer = null;
      }
      return new Promise((resolve) => {
        const payload = {
          name: contents.profile.name,
          enabled: contents.profile.enabled,
          parent_client: contents.profile.parent_customer,
          primary_analyst: contents.profile.primary_analyst,
          secondary_analyst: contents.profile.secondary_analyst,
        };

        createCustomer(payload, {}).then(function (result) {
          resolve(result.data);
        });
      });
    },
    deleteCustomer(context, customerId) {
      return new Promise((resolve) => {
        deleteCustomer({}, { client_id: customerId }).then((r) => resolve(r));
      });
    },

    createCustomerUser(context, contents) {
      return new Promise((resolve, reject) => {
        listUsers({}, { results_per_page: 3000 })
          .then(function (result) {
            const checkUser = result.data.data.filter(function (user) {
              return user.email == contents.user.email;
            });
            if (checkUser.length == 1) {
              const payload = {
                user_id: checkUser[0].id,
              };
              const params = {
                client_id: contents.client_id,
              };
              customersCustomerIdUsersPost(payload, params)
                .then(function (result) {
                  console.log(result);
                  resolve(result.data);
                })
                .catch((error) => {
                  reject(error.response.data);
                });
            } else {
              const payload = {
                email: contents.user.email,
                first_name: contents.user.first_name,
                last_name: contents.user.last_name,
              };
              const params = {};
              createUser(payload, params)
                .then(function (result) {
                  console.log(result);
                  if (result.data.data) {
                    const payload = {
                      user_id: result.data.data.id,
                    };
                    const params = {
                      client_id: contents.client_id,
                    };
                    customersCustomerIdUsersPost(payload, params)
                      .then(function (result) {
                        resolve(result.data);
                      })
                      .catch((error) => {
                        reject(error.response.data);
                      });
                  }
                })
                .catch((error) => {
                  reject(error.response.data);
                });
            }
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    createEmailDomain(context, contents) {
      return new Promise((resolve) => {
        const payload = {
          domain: contents.domain,
        };
        const params = {
          client_id: contents.client_id,
        };
        customersCustomerIdEmailDomainsPost(payload, params).then(function (
          result
        ) {
          resolve(result.data);
        });
      });
    },
    updateClient(context, contents) {
      console.log(
        contents.profile,
        context.state.profile,
        typeof contents.profile.parent_customer
      );

      if (
        typeof contents.profile.parent_customer !== "string" &&
        contents.profile.parent_customer !== null &&
        contents.profile.parent_customer !== undefined
      ) {
        contents.profile.parent_customer = contents.profile.parent_customer.id;
      }

      if (
        typeof contents.profile.primary_analyst_id !== "string" &&
        contents.profile.primary_analyst_id !== null &&
        contents.profile.primary_analyst_id !== undefined
      ) {
        contents.profile.primary_analyst_id =
          contents.profile.primary_analyst.id;
      }

      if (
        typeof contents.profile.secondary_analyst_id !== "string" &&
        contents.profile.secondary_analyst_id !== null &&
        contents.profile.secondary_analyst_id !== undefined
      ) {
        contents.profile.secondary_analyst_id =
          contents.profile.secondary_analyst.id;
      }

      const payload = {
        name: contents.profile.name,
        enabled: contents.profile.enabled,
        parent_client: contents.profile.parent_customer,
        primary_analyst: contents.profile.primary_analyst_id,
        secondary_analyst: contents.profile.secondary_analyst_id,
      };

      const params = {
        client_id: contents.client_id,
      };

      const pay = {};
      const par1 = {
        client_id: contents.client_id,
        user_id: contents.profile.previous_primary_analyst,
      };
      const par2 = {
        client_id: contents.client_id,
        user_id: contents.profile.previous_secondary_analyst,
      };

      if (
        contents.profile.previous_primary_analyst &&
        contents.profile.previous_secondary_analyst
      ) {
        return new Promise((resolve) => {
          deleteCustomerUser(pay, par1)
            .then(function (result) {
              console.log(result.data);
              deleteCustomerUser(pay, par2)
                .then(function (res) {
                  console.log(res.data);
                  updateCustomer(payload, params)
                    .then(function (r) {
                      console.log(r.data);
                      resolve(r.data);
                    })
                    .catch(function (error) {
                      console.error(error);
                      return;
                    });
                })
                .catch(function (error) {
                  console.error(error);
                  return;
                });
            })
            .catch(function (error) {
              console.error(error);
              return;
            });
        });
      } else if (
        contents.profile.previous_primary_analyst &&
        !contents.profile.previous_secondary_analyst
      ) {
        return new Promise((resolve) => {
          deleteCustomerUser(pay, par1)
            .then(function (result) {
              console.log(result.data);

              updateCustomer(payload, params)
                .then(function (r) {
                  console.log(r.data);
                  resolve(r.data);
                })
                .catch(function (error) {
                  console.error(error);
                  return;
                });
            })
            .catch(function (error) {
              console.error(error);
              return;
            });
        });
      } else if (
        !contents.profile.previous_primary_analyst &&
        contents.profile.previous_secondary_analyst
      ) {
        return new Promise((resolve) => {
          deleteCustomerUser(pay, par2)
            .then(function (result) {
              console.log(result.data);

              updateCustomer(payload, params)
                .then(function (r) {
                  console.log(r.data);
                  resolve(r.data);
                })
                .catch(function (error) {
                  console.error(error);
                  return;
                });
            })
            .catch(function (error) {
              console.error(error);
              return;
            });
        });
      } else {
        return new Promise((resolve) => {
          updateCustomer(payload, params)
            .then(function (r) {
              console.log(r.data);
              resolve(r.data);
            })
            .catch(function (error) {
              console.error(error);
              return;
            });
        });
      }
    },
    getUsers(context, id) {
      return new Promise((resolve) => {
        const payload = {};
        const params = {
          results_per_page: 3000,
          page: 1,
          user_type: "internal",
        };
        listUsers(payload, params).then((result) => {
          if (result.data.data.length > 0) {
            context.commit("replaceValue", {
              key: "users",
              value: result.data.data,
            });
            resolve(result.data);
          }
        });
      });
    },
    getCustomerUsers(context, id) {
      return new Promise((resolve) => {
        // clientUser is the type of customer we want to get, the UUID
        // here is the UUID for clientUser in the database
        // idk why they couldn't just make the ID "client_user"...
        //const clientUser = "3cOy8LVuTRW3LZ31YbAoWg";
        const payload = {};
        const params = {
          client_id: id,
          //user_type: clientUser,
        };
        customersCustomerIdUsersGet(payload, params).then(function (result) {
          if (result.data.data.client_users.length > 0) {
            context.commit("replaceValue", {
              key: "customerUsers",
              value: result.data.data.client_users,
            });
            resolve(result.data);
          }
        });
      });
    },
    getEmailDomains(context, id) {
      const payload = {};
      const params = {
        client_id: id,
      };
      customersCustomerIdEmailDomainsGet(payload, params).then(function (
        result
      ) {
        if (result.data.data.length > 0) {
          context.commit("replaceValue", {
            key: "domains",
            value: result.data.data,
          });
        }
      });
    },
    getProfile(context, id) {
      return new Promise((resolve) => {
        const payload = {};
        const params = {
          client_id: id,
        };
        getCustomer(payload, params).then(function (result) {
          console.log(result.data.data);

          context.commit("replaceValue", {
            key: "profile",
            value: result.data.data,
          });
          resolve(result.data);
        });
      });
    },
    getProductsBegin(context, id) {
      return new Promise((resolve) => {
        const payload = {};
        const params = {
          client_id: id,
        };
        partnersGet({}, {}).then(function (result) {
          if (result.data.data.length > 0) {
            context.commit("replaceValue", {
              key: "partners",
              value: result.data.data,
            });
            resolve(result.data);
          }
        });
        customersCustomerIdPurchasesGet(payload, params).then(function (
          result
        ) {
          if (result.data.data.length > 0) {
            context.commit("replaceValue", {
              key: "products",
              value: result.data.data,
            });
          }
        });
      });
    },
    getProductsContinue(context, partner_id) {
      return new Promise((resolve) => {
        const payload = {};
        getProductByPartner(payload, {
          partner_id: partner_id,
        }).then(function (result) {
          context.commit("replaceValue", {
            key: "productList",
            value: result.data.data.sort((a, b) =>
              a.name.localeCompare(b.name)
            ),
          });
          resolve(result.data);
        });
      });
    },
    getProductsEnd(context, product_id) {
      return new Promise((resolve) => {
        const payload = {};
        getProductTerms(payload, {
          product_id: product_id,
        }).then(function (result) {
          context.commit("replaceValue", {
            key: "terms",
            value: result.data.data,
          });
          resolve(result.data);
        });
      });
    },
    createPurchase(context, contents) {
      return new Promise((resolve) => {
        const payload = {
          product: contents.product.name,
          partner: contents.product.partner,
          end_date: contents.product.end_date,
          start_date: contents.product.start_date,
          term: contents.product.term,
        };

        Object.keys(payload).forEach((key) => {
          if (payload[key] == null) {
            delete payload[key];
          }
        });

        const params = {
          client_id: contents.client_id,
        };
        customersCustomerIdPurchasesPost(payload, params)
          .then(function (result) {
            console.log(result);
            resolve(result.data);
          })
          .catch(function (error) {
            resolve(error.response.data);
            console.error(error);

            return;
          });
      });
    },
  },
  mutations: {
    clearState(state) {
      state.users = [];
      state.customerUsers = [];
      state.products = [];
      state.productList = [];
      state.domains = [];
      state.profile = {};
      state.customers = [];
      state.partners = [];
      state.terms = [];
    },
    replaceValue(state, { key, value }) {
      state[key] = value;
    },
  },
};

export default clientIdentity;
